import styled from 'styled-components'

export const ContactFormWrapper = styled.div`
  margin-top: -90px;
  padding-top: 90px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    margin-top: -120px;
    padding-top: 120px;
  }
`

export const Form = styled.form``

export const Container = styled.div``

export const Select = styled.select`
  min-height: 54px;
  display: block;
  color: ${({ theme }) => theme.colors.contactForm.input.placeholder};
  background-color: ${({ theme }) => theme.colors.contactForm.input.background};
  border: none;
  margin-bottom: 12px;
  padding: 15px 22px;
  padding-right: 30px;
  border-radius: 66px;
  width: 100%;
  max-width: 398px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-size: ${({ theme }) => 12 / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    font-size: initial;
  }
`

export const ChevronIconWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: 20px;
  right: 25px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  > div {
    &:last-of-type {
      width: 100%;
      max-width: 398px;
    }
  }

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ButtonContainer = styled.div`
  @media (${({ theme }) => theme.mediaQueries.xl}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

export const CaptchaWrapper = styled.div`
  margin-left: auto;

  & div {
    margin-left: auto;
  }
`
