// React components
import Contact from '../Contact/Contact'

// Styled Components
import { ContactsWrapper, Container } from './Contacts.styles'
import { Title } from '../../styled-components/shared/common'
import Spacer from '../../styled-components/shared/Spacer'

// Types
import { ContactsProps } from './Contacts.types'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'

const Contacts = ({ contacts }: ContactsProps) => {
  const contactsLabel = useDictionaryLine(dictionary.contact.contactsLabel)
  return (
    <ContactsWrapper>
      <Title type='red'>{contactsLabel}</Title>
      <Spacer size={18} when={{ lg: 30 }} />
      <Container>
        {contacts.map((contact, i) => {
          const contactsArr: string[] = [
            contact.name ? contact.name : 'AVE',
            ...(contact.phoneNumbers?.map(p => p.phoneNumber || '') || []),
            ...(contact.mobileNumbers?.map(p => p.phoneNumber || '') || []),
            ...(contact.emails?.map(p => p.email) || []),
          ]
          return (
            <Contact
              key={i}
              header={contact.company}
              content={contactsArr}
              open={contacts.length > 3 ? false : true}
            />
          )
        })}
      </Container>
    </ContactsWrapper>
  )
}
export default Contacts
