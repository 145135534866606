import styled from 'styled-components'

export const EstablishmentCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  width: 100%;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    gap: 40px;
  }

  // @media (${({ theme }) => theme.mediaQueries.md}) {
  //   grid-template-columns: 1fr 1fr;
  //   row-gap: 60px;
  //   column-gap: 16px;
  // }

  // @media (${({ theme }) => theme.mediaQueries.lg}) {
  //   grid-template-columns: repeat(3, 1fr);
  // }

  // @media (${({ theme }) => theme.mediaQueries.xxl}) {
  //   grid-template-columns: repeat(4, 1fr);
  // }
`
