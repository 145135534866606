// React components
import EstablishmentCard from '../EstablishmentCard/EstablishmentCard'

// Styled Components
import { EstablishmentCardsWrapper } from './EstablishmentCards.styles'

// Types
import { EstablishmentCardsProps } from './EstablishmentCards.types'

const EstablishmentCards = ({ locations }: EstablishmentCardsProps) => {
  return (
    <EstablishmentCardsWrapper>
      {locations.map(location => {
        return <EstablishmentCard key={location.id} location={location} />
      })}
    </EstablishmentCardsWrapper>
  )
}
export default EstablishmentCards
