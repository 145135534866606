// Styled Components
import { InstagramIconWrapper } from './InstagramIcon.styles'

const InstagramIcon = () => {
  return (
    <InstagramIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='23.676'
      height='23.674'
      viewBox='0 0 23.676 23.674'
    >
      <g data-name='Group 282' transform='translate(-1377.361 -926.062)'>
        <g data-name='Group 122' transform='translate(1377.361 926.062)'>
          <g fill='#d90017' data-name='Group 121'>
            <path
              d='M1389.193 949.732c-1.14 0-2.279.013-3.419 0a14.958 14.958 0 01-3.308-.282 6.109 6.109 0 01-4.847-4.93 12.414 12.414 0 01-.246-2.7q-.022-3.925 0-7.85a11.331 11.331 0 01.373-3.2 6.1 6.1 0 014.811-4.442 12.372 12.372 0 012.7-.248q3.934-.022 7.868 0a11.56 11.56 0 013.1.345 6.092 6.092 0 014.553 4.874 15.062 15.062 0 01.252 3.2q.02 3.392 0 6.784a14.663 14.663 0 01-.277 3.327 6.108 6.108 0 01-4.97 4.871 15.364 15.364 0 01-3.131.25c-1.152.013-2.307.001-3.459.001zm.007-21.6v.036c-.953 0-1.906-.014-2.858 0a18.364 18.364 0 00-3.262.211 4 4 0 00-3.313 3.024 8.809 8.809 0 00-.262 2.311 574.203 574.203 0 00-.029 7.307 15.26 15.26 0 00.205 2.991 4.009 4.009 0 003.046 3.314 8.469 8.469 0 002.222.255q4.241.018 8.483 0a10.549 10.549 0 001.851-.174 3.977 3.977 0 003.348-3.049 8.862 8.862 0 00.256-2.312q.019-4.151 0-8.3a9.665 9.665 0 00-.208-2.12 4.008 4.008 0 00-1.986-2.757 5.471 5.471 0 00-2.539-.626c-1.654-.051-3.302-.075-4.954-.11z'
              data-name='Path 34'
              transform='translate(-1377.361 -926.063)'
            ></path>
            <path
              d='M1494.028 1036.635a6.083 6.083 0 11-6.08 6.011 6.078 6.078 0 016.08-6.011zm.006 2.117a3.962 3.962 0 103.955 3.961 3.971 3.971 0 00-3.955-3.961z'
              data-name='Path 35'
              transform='translate(-1482.196 -1030.883)'
            ></path>
            <path
              d='M1700.438 1007.625a1.419 1.419 0 111.4-1.451 1.424 1.424 0 01-1.4 1.451z'
              data-name='Path 36'
              transform='translate(-1682.261 -1000.693)'
            ></path>
          </g>
        </g>
      </g>
    </InstagramIconWrapper>
  )
}
export default InstagramIcon
