import styled from 'styled-components'

export const BreadcrumbWrapper = styled.nav`
  position: relative;
  z-index: 1;
`

export const NavList = styled.ol`
  display: flex;
  gap: 6px;
`
