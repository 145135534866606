import styled from 'styled-components'

export const MapBox = styled.div`
  min-height: 244px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    min-height: 600px;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    min-height: 941px;
  }
`
