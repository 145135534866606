// React
import { useState, useEffect } from 'react'

// React components (svg)
import ArrowCircleIcon from '../svgs/icons/ArrowCircleIcon'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// Styled Components
import {
  ContactWrapper,
  Header,
  Content,
  ContentWrapper,
  ContentItem,
  CircleArrowIconWrapper,
} from './Contact.styles'

// Types
import { ContactProps } from './Contact.types'

const Contact = ({ header, content, open = false }: ContactProps) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  function isEmail(email: string) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  return (
    (<ContactWrapper>
      <Header
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
      >
        <CircleArrowIconWrapper
          animate={{
            rotate: isOpen ? '0deg' : '180deg',
            transition: { duration: 0.3, ease: 'easeInOut' },
          }}
        >
          <ArrowCircleIcon />
        </CircleArrowIconWrapper>
        {header}
      </Header>
      <AnimatePresence>
        {isOpen && (
          <ContentWrapper
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: { duration: 0.3, ease: 'easeInOut' },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: { duration: 0.3, ease: 'easeInOut' },
            }}
          >
            <Content>
              {content.map((item, i) => {
                if (item) {
                  if (i > 0) {
                    if (isEmail(item)) {
                      return (
                        <ContentItem key={i}>
                          <a href={`mailto:${item}`} title={item}>
                            {item}
                          </a>
                        </ContentItem>
                      )
                    } else {
                      return (
                        (<ContentItem key={i}>
                          <a
                            href={`tel:${item?.replace(/\s/g, '')}`}
                            title={item}
                          >
                            {item}
                          </a>
                        </ContentItem>)
                      );
                    }
                  } else {
                    return <ContentItem key={i}>{item}</ContentItem>
                  }
                }
              })}
            </Content>
          </ContentWrapper>
        )}
      </AnimatePresence>
    </ContactWrapper>)
  );
}
export default Contact
