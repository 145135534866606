import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

export const ContactWrapper = styled.div``

export const CircleArrowIconWrapper = motion(styled.div`
  flex-shrink: 0;
`)

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  font-weight: 700;

  svg {
    g {
      g {
        transition: fill 0.3s;

        path {
          transition: stroke 0.3s;
        }
      }
    }
  }

  &:hover {
    svg {
      g {
         {
          &:last-of-type {
            path {
              stroke: ${({ theme }) => theme.colors.contact.icon.arrowHover};
            }
          }

          &:first-of-type {
            fill: ${({ theme }) => theme.colors.contact.icon.backgroundHover};
          }
        }
      }
    }
  }
`

export const Content = styled.ul`
  padding-top: 12px;
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const ContentWrapper = motion(styled.div``)

export const ContentItem = styled.li``
