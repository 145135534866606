// React components
import Crumb from './Crumb'

// Styled Components
import { BreadcrumbWrapper, NavList } from './Breadcrumb.styles'

// Types
import { BreadcrumbProps } from './Breadcrumb.types'

const Breadcrumb = ({ children, whiteOnLarge = false }: BreadcrumbProps) => {
  return (
    <BreadcrumbWrapper aria-label='Drobečková navigace'>
      <NavList>
        <Crumb whiteOnLarge={whiteOnLarge} href='/'>
          AVE
        </Crumb>
        {children}
      </NavList>
    </BreadcrumbWrapper>
  )
}
export default Breadcrumb
