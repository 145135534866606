const ArrowCircleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29'
      height='29'
      viewBox='0 0 29 29'
    >
      <g data-name='Group 142' transform='translate(-795.5 -858.5)'>
        <g
          fill='none'
          stroke='#d90017'
          strokeWidth='1'
          data-name='Ellipse 10'
          transform='translate(795.5 858.5)'
        >
          <circle cx='14.5' cy='14.5' r='14.5' stroke='none'></circle>
          <circle cx='14.5' cy='14.5' r='14'></circle>
        </g>
        <g data-name='Group 7' transform='translate(803.5 869.5)'>
          <path
            fill='none'
            stroke='#e2001a'
            strokeWidth='1.5'
            d='M0 6.054L6.052 0 12 5.947'
            data-name='Path 8'
          ></path>
        </g>
      </g>
    </svg>
  )
}
export default ArrowCircleIcon
