// Next.js
import Link from '../../Link'

// Styled Components
import { CrumbWrapper, CrumbLink } from './Crumb.styles'

// Types
import { CrumbProps } from './Crumb.types'

const Crumb = ({
  href,
  children,
  isCurrentPage,
  whiteOnLarge = false,
}: CrumbProps) => {
  return (
    <CrumbWrapper whiteOnLarge={whiteOnLarge}>
      <Link href={href} passHref legacyBehavior>
        <CrumbLink
          whiteOnLarge={whiteOnLarge}
          aria-current={isCurrentPage ? 'page' : undefined}
        >
          {children}
        </CrumbLink>
      </Link>
    </CrumbWrapper>
  )
}
export default Crumb
