// Next.js
import Link from '../Link'
import { apiRegionKeyToSlug } from '../../utils/constants'

// React components
import Button from '../Button'

// Styled Components
import {
  EstablishmentCardWrapper,
  Title,
  Text,
  TextWrapper,
  ButtonWrapper,
} from './EstablishmentCard.styles'

// Types
import { EstablishmentCardProps } from './EstablishmentCard.types'

const EstablishmentCard = ({ location }: EstablishmentCardProps) => {
  const href = `/kontakt/${apiRegionKeyToSlug(
    location.attributes.region || '',
  )}/${location.attributes.slug}`

  return (
    <EstablishmentCardWrapper>
      <TextWrapper>
        <Title>{location.attributes.name}</Title>
        <Text>{location.attributes.address?.Address}</Text>
        <div>
          {location.attributes.contacts &&
            location.attributes.contacts.length > 0 && (
              <>
                {location.attributes.contacts?.[0]?.phoneNumbers &&
                  location.attributes.contacts?.[0]?.phoneNumbers.length >
                    0 && (
                    <>
                      {location.attributes.contacts?.[0]?.phoneNumbers[0]
                        .phoneNumber && (
                        <p>
                          {
                            location.attributes.contacts?.[0]?.phoneNumbers[0]
                              .phoneNumber
                          }
                        </p>
                      )}
                    </>
                  )}
                {location.attributes.contacts?.[0]?.mobileNumbers &&
                  location.attributes.contacts?.[0]?.mobileNumbers.length >
                    0 && (
                    <>
                      {location.attributes.contacts?.[0]?.mobileNumbers[0]
                        .phoneNumber && (
                        <p>
                          {
                            location.attributes.contacts?.[0]?.mobileNumbers[0]
                              .phoneNumber
                          }
                        </p>
                      )}
                    </>
                  )}
                {location.attributes.contacts?.[0]?.emails &&
                  location.attributes.contacts?.[0]?.emails.length > 0 && (
                    <>
                      {location.attributes.contacts[0].emails[0].email && (
                        <p>{location.attributes.contacts[0].emails[0].email}</p>
                      )}
                    </>
                  )}
              </>
            )}
        </div>
      </TextWrapper>
      <ButtonWrapper>
        <Link href={href} passHref legacyBehavior>
          <Button variant='outlined' arrow={true} link>
            detail
          </Button>
        </Link>
      </ButtonWrapper>
    </EstablishmentCardWrapper>
  )
}
export default EstablishmentCard
