import styled, { css } from 'styled-components'

// Types
import { CrumbLinkProps, CrumbWrapperProps } from './Crumb.types'

export const CrumbWrapper = styled.li<CrumbWrapperProps>`
  &:not(:first-of-type) {
    &::before {
      content: '/';
      color: ${({ theme }) => theme.colors.breadcrumb.link};
      margin-right: 6px;

      ${({ whiteOnLarge }) =>
        whiteOnLarge &&
        css`
          @media (min-width: 1820px) {
            color: ${({ theme }) => theme.colors.white};
          }
        `}
    }
  }
`

export const CrumbLink = styled.a<CrumbLinkProps>`
  color: ${({ theme }) => theme.colors.breadcrumb.link};
  text-decoration: none;

  &:first-letter {
    text-transform: capitalize;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.breadcrumb.linkHover};
  }

  ${({ whiteOnLarge }) =>
    whiteOnLarge &&
    css`
      @media (min-width: 1820px) {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;

        &:hover {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `}
`
