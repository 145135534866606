// Styled Components
import { FacebookIconWrapper } from './FacebookIcon.styles'

const FacebookIcon = () => {
  return (
    <FacebookIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='11.398'
      height='23.67'
      viewBox='0 0 11.398 23.67'
    >
      <g data-name='Group 281' transform='translate(-599.739 -904.479)'>
        <path
          fill='#d90017'
          d='M599.739 911.288h2.476v-1.267a8.424 8.424 0 01.452-3.175 3.592 3.592 0 011.668-1.708 6.4 6.4 0 013.072-.659 12.367 12.367 0 013.73.573l-.6 3.111a8.846 8.846 0 00-2.048-.255 1.829 1.829 0 00-1.389.453 2.611 2.611 0 00-.422 1.738v1.189h3.335v3.51h-3.335v13.35h-4.461V914.8h-2.476z'
          data-name='Path 33'
        ></path>
      </g>
    </FacebookIconWrapper>
  )
}
export default FacebookIcon
