const ShareIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.74'
      height='23.674'
      viewBox='0 0 16.74 23.674'
    >
      <path
        fill='#d90017'
        d='M3218.387 213.575a3.016 3.016 0 011.575.455l6.359-4.87a3.046 3.046 0 11.972 1.182l-6.268 4.8a2.983 2.983 0 01.394 1.468 3.032 3.032 0 01-.414 1.5l6.314 4.923a3.086 3.086 0 11-.984 1.176l-6.418-5a2.958 2.958 0 01-1.531.431 3.032 3.032 0 010-6.063z'
        data-name='Path 127'
        transform='translate(-3215.358 -204.864)'
      ></path>
    </svg>
  )
}
export default ShareIcon
