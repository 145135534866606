import styled from 'styled-components'

export const ContactsWrapper = styled.div`
  position: relative;
  z-index: 3;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
    justify-content: space-between;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
